<template>
  <div class="building-type">
    <navbar title="在售户型" />
    <div class="type-box" v-if="typeList && typeList.length > 0">
      <div class="type-item" v-for="item in typeList" :key="item.id">
        <div class="item-msg">
          <div class="item-name">{{item.title}}</div>
          <div class="item-time">{{item.createTime != null?getTimes.timestampToTime(item.createTime):""}}更新</div>
        </div>
        <div class="item-msg">
          <div class="item-text">户型：<span>{{item.room + '室' + item.hall + '厅' + item.toilet + '卫'}}</span></div>
          <div class="item-text">建面：<span>{{item.area}}㎡</span></div>
        </div>
        <div class="item-msg">
          <div class="item-text">总价：<span>{{item.price}}万</span></div>
          <div class="item-text">首付：<span>{{item.payment==0?"不支持":"支持"}}{{item.payment==0?'':'/'+item.downPayment+'万'}}</span></div>
        </div>
        <div class="item-msg">
          <div class="item-text">朝向：<span>{{item.orientation}}</span></div>
        </div>
        <div class="item-content">
          <div class="content-title">户型特点：</div>
          <div class="content-msg" v-html="$xss(item.structure)"></div>
        </div>
        <div class="item-bottom" @click="showImg(item.pic)">
          <img class="item-img" :src="item.pic" alt="">
        <img class="item-panorama" v-if="item.viewPic" @click.stop="showPanorama(item.viewPic)" src="../../assets/images/360°.png" alt="">
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无户型"/>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import { ImagePreview } from "vant";

export default {
  name: "buildingType",
  data() {
    return {
      typeList: [
      ],
      id: "",
      type: "",
    };
  },
  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
    //获取传递过来的类型 //type -> houseType: 根据户型id查户型，house：根据楼房id查户型
    this.type = this.$route.query.type;
  },
  methods: {
    showImg(img) {
      ImagePreview({
        images: [img],
        showIndex: false,
      });
    },
    showPanorama(url){
        window.location.href = url
    },
    getHouseTypeList() {
      const data = {
            //楼盘id
            id: this.id,
            type: this.type,
          }
        this.$post('home/getHouseTypeList',data).then((res) => {
          if (res.status == 200) {

            this.typeList = res.data.houseTypes
 
          }else {
            this.$toast.fail(res.msg);
          }
        });
    },
  },
  components: {
    navbar,
  },
  mounted() {
      this.getHouseTypeList();
    },
};
</script>
<style lang="less" scoped>
.building-type {
  .type-box {
    .type-item {
      padding: 12px;
      background-color: #fff;
      margin-bottom: 10px;
      .item-msg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .item-name {
          color: #333;
          font-size: 16px;
          width:210px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .item-time {
          color: #777;
          font-size: 12px;
        }
        .item-text {
          color: #777;
          font-size: 14px;
          span {
            color: #333;
          }
        }
      }
      .item-content {
        padding-top: 10px;
        border-top: 2px solid #eee;

        .content-title {
          color: #858585;
          font-size: 14px;
        }
        .content-msg {
            color: #333;
            font-size: 14px;
          img {
            max-width: 100%;
          }
        }
      }
      .item-bottom {
          position: relative;
        .item-img {
          
          width: 100%;
        }
        .item-panorama{
            width: 88px;
            height: 30px;
            position: absolute;
            z-index: 99;
            top: 20px;
            right: 10px;
        }
      }
    }
  }
}
</style>